import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { removeCookie } from "../utils/helper";
import styles from "./BasicLayout.module.scss";
import { Menu, Typography, Button, Dropdown, Drawer } from "antd";
import { userLogout } from "../Redux/Features/User/userLogoutSlice";
import cc from "classnames";
import { MenuOutlined } from "@ant-design/icons";
import BurgerMenu from "../components/burgerMenu";
import { toastSuccess } from "../utils/toast";
import { getPromptlet } from "../Redux/Features/Promptlet/getPromptletSlice";
import EditPromptletModal from "../components/Promptlets/EditPromptletModal";
const BasicLayout = ({
  children,
  title,
  isPadding = true,
  setIsMergeDocumentModalVisible,
  problemData,
  isMenu = true,
  handleCompileChats,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isDrawerVisible, setIsDrawerVisible] = useState(false);
  const [isMdOrAbove, setIsMdOrAbove] = useState(window.innerWidth >= 768);
  const [isSMOrBellow, setIsSMOrBellow] = useState(window.innerWidth < 768);
  const [selectedTab, setSelectedTab] = useState([]);
  const user = useSelector((state) => state.getUser.user);
  const { isLoading: updatePromptletLoading } = useSelector(
    (state) => state?.updatePromptlet
  );
  const isOwner = problemData?.library?.user === user?._id;
  const [promptletToEditModal, setPromptletToEditModal] = useState({
    open: false,
    id: "",
    title: "",
    steps: [],
    visibility: "",
  });
  let [clientErrors, setClientErrors] = useState({});
  const handleLogout = () => {
    dispatch(
      userLogout({
        onSuccess: () => {
          toastSuccess("User Logged out successfuly");
          sessionStorage.removeItem("tokenExpiration");
          removeCookie("token");
          navigate(`/`);
        },
        onError: () => {},
      })
    );
  };

  useEffect(() => {
    let pathName = `/${window.location?.pathname?.split("/")[1]}`;
    if (pathName === "/home" || pathName === "/chat") {
      setSelectedTab((prev) => {
        return ["1"];
      });
    }
    if (pathName === "/threads") {
      setSelectedTab((prev) => {
        return ["2"];
      });
    }
  }, []);
  useEffect(() => {
    const handleResize = () => {
      setIsMdOrAbove(window.innerWidth >= 768);
      setIsSMOrBellow(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const showDrawer = () => {
    setIsDrawerVisible(true);
  };

  const closeDrawer = () => {
    setIsDrawerVisible(false);
  };
  const handlePromptletNameClick = (id) => {
    dispatch(
      getPromptlet({
        id,
        onSuccess: (promptlet) => {
          setPromptletToEditModal((prevState) => ({
            ...prevState,
            open: true,
            id: promptlet._id,
            steps: promptlet.steps,
            title: promptlet.name,
            visibility: promptlet.visibility,
          }));
        },
      })
    );
  };
  return (
    <div className={styles.basic_layout_container}>
      <div className={styles.layout_container}>
        <div
          className={cc(styles.children_container)}
          style={{
            padding: !isPadding && "0",
          }}
        >
          {title && <Typography.Title level={2}>{title}</Typography.Title>}

          {isMenu && (
            <div className={styles.chat_menu_container}>
              <div className={styles.auto_container}>
                <div className={styles.menu_section}>
                  <Dropdown
                    overlay={BurgerMenu(handleLogout, navigate, user)}
                    trigger={["click"]}
                  >
                    <MenuOutlined
                      className={styles.burger_icon}
                      style={{ color: "#000", paddingLeft: "1rem" }}
                    />
                  </Dropdown>

                  <Menu
                    defaultSelectedKeys={selectedTab}
                    selectedKeys={selectedTab}
                    mode="horizontal"
                    items={[
                      {
                        key: "1",
                        label: "Problems",
                        onClick: () => {
                          navigate(`/home`);
                        },
                      },
                      {
                        key: "2",
                        label: "Threads",
                        onClick: () => {
                          navigate(`/threads`);
                        },
                      },
                      ...(user?.userType === "admin"
                        ? [
                            {
                              key: "4",
                              label: "Admin Only Threads",
                              onClick: () => {
                                navigate(`/admin-only-threads`);
                              },
                            },
                          ]
                        : []),

                      {
                        key: "3",
                        label: "Promptlets",
                        onClick: () => {
                          navigate(`/promptlets`);
                        },
                      },
                      ...(user?.userType === "admin"
                        ? [
                            {
                              key: "4",
                              label: "Users",
                              onClick: () => {
                                navigate(`/users`);
                              },
                            },
                          ]
                        : []),
                    ]}
                  />
                </div>
                {problemData &&
                  !isPadding &&
                  (isSMOrBellow ? (
                    <Typography.Paragraph
                      className={styles.drawerIcon}
                      onClick={showDrawer}
                    >
                      ::
                    </Typography.Paragraph>
                  ) : (
                    <Typography.Paragraph className={styles.libraryTitle}>
                      {problemData?.title} <span>::</span>{" "}
                      <span
                        style={{
                          cursor: isOwner ? "pointer" : "default",
                          color: "#1890ff", // Add a link-like color (blue)
                          textDecoration: isOwner ? "underline" : "none", // Underline the text
                          display: "inline-flex",
                          alignItems: "center",
                        }}
                        onClick={
                          isOwner
                            ? () =>
                                handlePromptletNameClick(
                                  problemData?.library?._id
                                )
                            : () => {}
                        }
                      >
                        {problemData?.library?.name}
                      </span>
                    </Typography.Paragraph>
                  ))}
              </div>
            </div>
          )}

          {children}
          {!isPadding && (
            <div className={styles.buttonGroup}>
              <Button type="dashed" onClick={handleCompileChats}>
                Compile to Document
              </Button>
              <Button
                type="dashed"
                onClick={() => setIsMergeDocumentModalVisible(true)}
              >
                Merge Document
              </Button>
            </div>
          )}

          <Drawer
            title="Problem Details"
            placement="right"
            onClose={closeDrawer}
            visible={isDrawerVisible}
          >
            <Typography.Paragraph>
              Title: {problemData?.title}
            </Typography.Paragraph>
            <Typography.Paragraph>
              Library: {problemData?.library?.name}
            </Typography.Paragraph>
          </Drawer>
          {isOwner && (
            <EditPromptletModal
              promptletToEditModal={promptletToEditModal}
              setPromptletToEditModal={setPromptletToEditModal}
              updatePromptletLoading={updatePromptletLoading}
              setClientErrors={setClientErrors}
              clientErrors={clientErrors}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default BasicLayout;
