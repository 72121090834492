import React, { useEffect } from "react";
import {
  Row,
  Col,
  Button,
  Input,
  Modal,
  Select,
  Typography,
  Form,
  Collapse,
  Card,
} from "antd";
import styles from "../../Pages/Home/Home.module.scss";
import handleUpdatePromptlet from "../../utils/promptlet/handleUpdatePromptlet";
import TextArea from "antd/es/input/TextArea";
import { useDispatch } from "react-redux";

const { Option } = Select;
const { Panel } = Collapse;

const EditPromptletModal = ({
  promptletToEditModal,
  setPromptletToEditModal,
  updatePromptletLoading,
  setClientErrors,
  clientErrors,
}) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  useEffect(() => {
    form.setFieldsValue(promptletToEditModal);
  }, [promptletToEditModal]);

  const handleSave = () => {
    form
      .validateFields()
      .then((values) => {
        handleUpdatePromptlet(
          { ...promptletToEditModal, ...values },
          setClientErrors,
          dispatch,
          setPromptletToEditModal
        );
      })
      .catch((error) => {
        console.error("Validation Failed:", error);
      });
  };

  return (
    <Modal
      centered
      open={promptletToEditModal?.open}
      onCancel={() =>
        setPromptletToEditModal({
          open: false,
          id: "",
          title: "",
          steps: [],
          visibility: "",
        })
      }
      footer={[
        <Button
          key="update"
          type="primary"
          onClick={handleSave}
          loading={updatePromptletLoading}
        >
          {updatePromptletLoading ? "Updating..." : "Update"}
        </Button>,
      ]}
      width={900}
    >
      <div className={styles.edit_problem_container}>
        <Form
          form={form}
          initialValues={promptletToEditModal}
          layout="vertical"
        >
          <Typography.Title level={3}>Edit Promptlet</Typography.Title>
          <Row gutter={16}>
            <Col span={18}>
              <Form.Item
                label="Promptlet Title"
                name="title"
                rules={[{ required: true, message: "Please enter a title" }]}
              >
                <Input
                  placeholder="Promptlet Title"
                  size="large"
                  onChange={(e) =>
                    setPromptletToEditModal({
                      ...promptletToEditModal,
                      title: e.target.value,
                    })
                  }
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label="Visibility">
                <Select
                  value={promptletToEditModal?.visibility}
                  onChange={(value) => {
                    setPromptletToEditModal((prevPromptletToEdit) => ({
                      ...prevPromptletToEdit,
                      visibility: value,
                    }));
                  }}
                >
                  <Option value="private">Private</Option>
                  <Option value="sharedUnlocked">Shared (Unlocked)</Option>
                  <Option value="sharedLocked">Shared (Locked)</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          {clientErrors?.title && (
            <span className={styles.error_message}>{clientErrors?.title}</span>
          )}

          <Typography.Title level={4}>Steps</Typography.Title>
          <Collapse accordion>
            {promptletToEditModal?.steps?.map((step, index) => (
              <Panel
                header={
                  <div style={{ textAlign: "left" }}>
                    {`Step ${index + 1} | ${step.key} | ${step.title}`}
                  </div>
                }
                key={index}
              >
                <Card>
                  <Row gutter={16}>
                    <Col span={6}>
                      <Form.Item
                        label="Key"
                        name={["steps", index, "key"]}
                        rules={[{ required: true, message: "Key is required" }]}
                      >
                        <Input placeholder="Key" />
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item
                        label="Title"
                        name={["steps", index, "title"]}
                        rules={[
                          { required: true, message: "Title is required" },
                        ]}
                      >
                        <Input placeholder="Title" />
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item
                        label="Order"
                        name={["steps", index, "order"]}
                        rules={[
                          { required: true, message: "Order is required" },
                        ]}
                      >
                        <Input type="number" placeholder="Order" />
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item
                        label="Persona"
                        name={["steps", index, "persona"]}
                        rules={[
                          { required: true, message: "Persona is required" },
                        ]}
                      >
                        <Input placeholder="Persona" />
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item
                        label="Task"
                        name={["steps", index, "task"]}
                        rules={[
                          { required: true, message: "Task is required" },
                        ]}
                      >
                        <TextArea placeholder="Task" />
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item
                        label="Input"
                        name={["steps", index, "input"]}
                        rules={[
                          { required: true, message: "Input is required" },
                        ]}
                      >
                        <TextArea placeholder="Input" />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        label="Format"
                        name={["steps", index, "format"]}
                      >
                        <Input placeholder="Format" />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        label="Active"
                        name={["steps", index, "active"]}
                      >
                        <Select placeholder="Active">
                          <Option value={true}>True</Option>
                          <Option value={false}>False</Option>
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item
                        label="AI Checker"
                        name={["steps", index, "aiChecker"]}
                      >
                        <Input placeholder="AI Checker" />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item
                        label="AI Checker Instructions"
                        name={["steps", index, "aiCheckerInstructions"]}
                      >
                        <Input placeholder="AI Checker Instructions" />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item
                        label="AI Checker Threshold"
                        name={["steps", index, "aiCheckerThreshold"]}
                      >
                        <Input
                          type="number"
                          placeholder="AI Checker Threshold"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item
                        label="Human Checker Style"
                        name={["steps", index, "humanCheckerStyle"]}
                      >
                        <Select
                          placeholder="Human Checker Style"
                          defaultValue="never"
                        >
                          <Option value="never">Never</Option>
                          <Option value="always">Always</Option>
                          <Option value="threshold">Threshold</Option>
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item
                        label="Human Checker Prompt"
                        name={["steps", index, "humanCheckerPrompt"]}
                      >
                        <Input placeholder="Human Checker Prompt" />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item
                        label="Human Checker Threshold"
                        name={["steps", index, "humanCheckerThreshold"]}
                      >
                        <Input
                          type="number"
                          placeholder="Human Checker Threshold"
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Card>
              </Panel>
            ))}
          </Collapse>

          {clientErrors && (
            <div className={styles.client_errors}>
              {Object.entries(clientErrors).map(([key, error]) => (
                <div key={key} className={styles.error_message}>
                  {error}
                </div>
              ))}
            </div>
          )}
        </Form>
      </div>
    </Modal>
  );
};

export default EditPromptletModal;
